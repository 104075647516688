// MANUAL CLONE 05
import moment from 'moment';
import Share from 'react-native-share';
import * as yup from 'yup';
import { Linking, Platform } from 'react-native';
// import {MaskService} from 'react-native-masked-text';
import { Assets } from '../resources';
import { formatMoney } from 'dumdee/utils';

export const DIRECTLY_SOURCE = ['Skrill'];

export const labelSecondaryDocument = (country) => {
  let label = '';

  switch (country) {
    case 'México':
      label = 'INE';
      break;

    default:
      label = 'documento';
      break;
  }

  return label;
};

export const documentTypes = (country) => {

  let dniLabel = 'Documento de identidad';

  switch (country) {
    case 'México':
      dniLabel = 'Credencial para votar (CURP)';
      break;
    case 'Chile':
      dniLabel = 'RUT';
      break;
    case 'Argentina':
      dniLabel = 'DNI';
      break;
    case 'Colombia':
      dniLabel = 'CC';
      break;
    default:
      break;
  }

  let documents = [
    {
      label: dniLabel,
      value: 'dni',
    },
    {
      label: 'Pasaporte',
      value: 'passport',
    }
  ];


  if (country === 'México') {
    documents.pop();
  }

  return (documents)
};

export const isValidEmail = (email) => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email,
  );
};

export const isNumber = (number) => {
  return /^\d+$/.test(number);
};

export const isValidDateDDMMYYYY = (date) => {
  return /^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]\d{4}$/.test(
    date,
  );
};

export const isRut = (value) => {
  return /^[0-9]+[\dkK]$/.test(value);
};

export const isPhone = (phone) => {
  return /^\d{9}$/.test(phone);
};

export const documentsYup = {
  cl: yup
    .string()
    .matches(
      // /^0*(\d{1,3}(\.\d{3})*)\-?([\dkK])$/,
      /^[0-9]+[\dkK]$/,
      'Ingrese sin puntos y guiones',
    )
    .min(5, 'Ingrese sin puntos y guiones'),
  /*.test(
      'document_number',
      'Formato inválido, ejemplo: 11.111.111-1/11.111.111-K',
      (value) => {
        return value.length > 3
          ? /^0*(\d{1,3}(\.\d{3})*)\-?([\dkK])$/.test(value)
          : false;
      },
    ),*/
  mx: yup
    .string()
    .min(6, 'Mínimo 6 caracteres')
    .max(22, 'Máximo 22 caracteres'),
  all: yup
    .string()
    .matches(/^[0-9]+$/, 'Solo números')
    .min(6, 'Mínimo 6 digitos')
    .max(15, 'Máximo 15 digitos'),
  allCompanyId: yup
    .string()
    .min(6, 'Mínimo 6 digitos')
    .max(15, 'Máximo 15 digitos'),
  allDni: yup.string().min(6, 'Mínimo 6 digitos').max(15, 'Máximo 15 digitos'),
  passport: yup
    .string()
    .min(6, 'Mínimo 6 digitos')
    .max(11, 'Máximo 11 digitos'),
};

export const prepareHeaders = (
  params = {
    token: '',
    client_id: '',
    uid: '',
    'access-token-mobile': '',
  },
) => {
  const { client_id, uid, token } = params;
  if (params['access-token-mobile']) {
    return {
      'access-token-mobile': params['access-token-mobile'],
      client: '',
      uid,
    };
  } else {
    return {
      'access-token': token,
      client: client_id,
      uid,
    };
  }
};

export const toNumber = (amount) => {
  amount = amount.replace(/\./g, '').replace(',', '.');
  return parseFloat(amount);
};

export function objectToQueryString(obj) {
  var str = [];
  for (var p in obj) {
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  }
  return str.join('&');
}

export const removeLeadingZerosBtc = (amountBtc, skip = false) => {
  if (skip) {
    return amountBtc;
  }

  amountBtc = Array.from(amountBtc);
  const tam = amountBtc.length - 1;
  let deleteDigits = true;

  for (let i = 0; i < tam && deleteDigits; i++) {
    if (amountBtc[tam - i] !== '0') {
      deleteDigits = false;
    }

    if (amountBtc[tam - i] === ',') {
      amountBtc[tam - i] = 'D';
    }

    if (amountBtc[tam - i] === '0' && deleteDigits) {
      amountBtc[tam - i] = 'D';
    }
  }

  amountBtc = amountBtc.join('');
  amountBtc = amountBtc.replace(/D/g, '');
  amountBtc = amountBtc[amountBtc.length - 1] === '.' || amountBtc[amountBtc.length - 1] === ',' ? `${amountBtc}00` : amountBtc
  return amountBtc;
};

export const linkingExternal = (url, target = '_blank') => {

  const handlePress = async () => {
    const supported = await Linking.canOpenURL(url);

    if (supported) {
      if (Platform.OS === 'web') {
        window.open(url, target);
      } else {
        await Linking.openURL(url);
      }
    }

  }

  handlePress();
};

export const getRestTimeForSessionExpiry = (session) => {
  const expiryTime = session.expiry;
  const seconds = moment(expiryTime).diff(moment(), 'seconds');
  return moment
    .utc(moment.duration(seconds, 'seconds').asMilliseconds())
    .format('mm:ss');
};

export const getTimeDifference = (expiryTime, format) => {
  const timeLeft = moment.unix(expiryTime).diff(moment().toDate().getTime());
  return moment(timeLeft > 0 ? timeLeft : 0).format(format);
};

export const isUserExpirySession = (session, minutes = 0) => {
  const now = moment().toDate().getTime();
  const expiryTime = moment(session.expiry)
    .subtract(minutes, 'minute')
    .toDate()
    .getTime();
  return expiryTime < now;
};

export const addPushNotificationFromCache = (addPushNotification) => {
  // eslint-disable-next-line no-undef
  const caches = window ? window.caches : null;

  if (Platform.OS === 'web' && caches) {
    caches.has('fcm-1').then(function (exist) {
      if (exist) {
        // eslint-disable-next-line no-undef
        caches.open('fcm-1').then(function (cache) {
          cache.match('/fcm').then((settings) => {
            if (settings && settings.body) {
              const reader = settings.body.getReader();
              reader.read().then(function processText({ done, value }) {
                // eslint-disable-next-line no-undef
                const notifications = JSON.parse(
                  // eslint-disable-next-line no-undef
                  new TextDecoder('utf-8').decode(value),
                );
                notifications.forEach((notification) => {
                  addPushNotification(notification);
                });
              });
            }
            cache.delete('/fcm');
          });
          // eslint-disable-next-line no-undef
          caches.delete('fcm-1');
        });
      }
    });
  }
};

export const CRYPTO_CURRENCIES = ['BTC', 'USDT', 'USDC'];

export const isCryptoCurrency = (currency) => {
  return CRYPTO_CURRENCIES.includes(currency.toUpperCase());
};

export const resolveRechargeMethod = (method, description) => {
  let resolve = '';

  switch (method) {
    case 'admin':
      resolve = 'Recarga desde administrador';
      break;
    case 'floyd':
      resolve = 'Transferencia bancaria';
      break;
    case 'fintoc':
      resolve = description;
      break;
    default:
      resolve = method;
      break;
  }

  return resolve;
}

export const renderEmail = (
  attributes,
  email,
) => {
  const { category = '', currency = '', to_address = '', recipient_email = '' } = attributes;

  switch (category) {
    case 'sent': {
      return currency.toUpperCase() === 'BTC' ? to_address : recipient_email;
    }
    case 'received': {
      return currency.toUpperCase() === 'BTC' ? to_address : email;
    }
    case 'deposit': {
      return email;
    }
    case 'withdrawal': {
      return recipient_email;
    }
    case 'transfer': {
      return recipient_email;
    }
    case 'exchange': {
      return currency.toUpperCase() === 'BTC' ? to_address : email;
    }
    case 'payment': {
      return email;
    }
    default: {
      return '';
    }
  }
};

export const renderLabel = (attributes, isNotification = false) => {
  const {
    category = '',
    currency = '',
    transfer_type = '',
    isBinance,
    to_address,
  } = attributes;

  if (isBinance) {
    return 'Recargaste Vita Wallet'
  } else {
    switch (category) {
      case 'sent': {
        return isCryptoCurrency(currency) && to_address ? 'Cripto' : 'Vita Usuario';
      }
      case 'received': {
        return isCryptoCurrency(currency) && to_address
          ? 'Cripto'
          : 'Vita Usuario';
      }
      case 'deposit': {
        return 'Recarga';
      }
      case 'withdrawal': {
        return 'Cuenta propia';
      }
      case 'transfer': {
        return 'Internacional';
      }
      case 'vita_card':
      case 'debit': {
        return transfer_type === 'debit' ? 'Compra' : 'Enviaste';
      }
      case 'credit':
      case 'disbursement': {
        return 'Recibiste';
      }
      case 'exchange': {
        return currency.toUpperCase() === 'BTC'
          ? 'Intercambio BTC'
          : `Intercambio ${currency.toUpperCase()}`;
      }
      case 'payment': {
        return 'Enlace de pago';
      }
      case 'service_payment': {
        return 'Pago de servicio';
      }
      case 'cash_back_coupon': {
        return 'Cashback';
      }
      case 'tax': {
        return 'Impuesto';
      }
      case 'fee': {
        return 'Comisión';
      }
      case 'adjustment': {
        return 'Ajuste';
      }
      case 'card_credit_adjustment':
      case 'card_debit_adjustment':
      case 'card_transaction':
      case 'card_maintenance':
      case 'request_charge': {
        return 'Pago';
      }
      default: {
        return '';
      }
    }
  }
};

export const addressCrypto = (address) => {
  return `${address.substring(0, 6)}...${address.substring(
    address.length - 6,
    address.length,
  )}`;
};

export const renderImage = ({ category = '', status = '' }) => {
  let icon = Assets.images.defaultAvatar;

  switch (category) {
    case 'exchange':
      icon = Assets.images.iExchange;
      break;
    case 'sent':
    case 'withdrawal':
    case 'transfer':
    case 'tax':
    case 'fee':
    case 'adjustment':
    case 'request_charge':
    case 'card_debit_adjustment':
    case 'card_transaction':
    case 'card_maintenance':
      icon = status === 'pending' || status === 'checking' ? Assets.images.iSendAnimated : Assets.images.iSend;
      break;
    case 'received':
    case 'deposit':
    case 'payment':
    case 'card_credit_adjustment':
      icon = Assets.images.iReceive;
      break;
    case 'service_payment':
      icon = Assets.images.iService;
      break;
    case 'vita_card':
    case 'disbursement':
    case 'debit':
    case 'credit':
      icon = Assets.images.cardBall;
      break;
    case 'cash_back_coupon':
      icon = Assets.images.couponDashboard;
      break;
    default:
      break;
  }

  return icon;
};

export const renderTransactionAction = ({
  category,
  currency,
  transfer_type,
  currency_to_default_currency_price,
  isBinance,
  description,
  account_bank,
  source,
}) => {
  if (isBinance) {
    return 'Binance Pay'
  } else {
    switch (category) {
      case 'sent': {
        return currency.toUpperCase() === 'BTC'
          ? 'Transf.: A otra wallet'
          : 'Transf.: A usuario de Vita';
      }
      case 'vita_card':
      case 'credit':
      case 'disbursement': {
        return 'Transf.: A vita card';
      }
      case 'debit': {
        return transfer_type === 'debit' ? 'Compra Vita Card' : '';
      }
      case 'received': {
        return 'Vita usuarios';
      }
      case 'deposit': {
        return DIRECTLY_SOURCE.includes(source) ? source : 'Transf. bancaria';
      }
      case 'withdrawal': {
        return currency.toUpperCase() === 'BTC'
          ? 'Transf.: A otra wallet'
          : 'Transf.: A otro banco';
      }
      case 'transfer': {
        return currency.toUpperCase() === 'BTC'
          ? 'Transf.: A otra wallet'
          : 'Transf.: A otro banco';
      }
      case 'exchange': {
        return currency.toUpperCase() === 'BTC'
          ? `Precio ${currency_to_default_currency_price
            ? formatMoney(currency_to_default_currency_price < 1 ? 1 / currency_to_default_currency_price : currency_to_default_currency_price, 'usd', 3, true)
            : '1,00'
          }` : 'Monto recibido';
      }
      case 'payment': {
        return 'Vita business';
      }
      case 'request_charge': {
        return description;
      }
      case 'card_debit_adjustment':
      case 'card_transaction':
      case 'card_credit_adjustment': {
        return `Tarjeta: ${account_bank?.last_digits || ''}`
      }
      default: {
        return '';
      }
    }
  }
};

export const renderTransactionSubTitle = (attributes) => {
  const {
    category,
    currency,
    recipient_email,
    to_address,
    currency_to_default_currency_price,
    description,
    transfer_type,
    card_acceptors,
    default_currency,
    account_bank,
    sender_email,
    purpose_comentary,
    source,
  } = attributes;

  let identifierCoupon = ''

  if (attributes.coupon_user) {

    const { coupon_user: { coupon: { identifier }, }, } = attributes
    identifierCoupon = identifier
  }
  const {
    name,
  } = card_acceptors && card_acceptors[0] ? card_acceptors[0] : {};

  switch (category) {
    case 'sent': {
      // return currency.toUpperCase() === 'BTC' || (currency.toUpperCase() === 'USDT' && to_address) ? to_address : recipient_email;
      return isCryptoCurrency(currency) && to_address ? addressCrypto(to_address) : recipient_email;
    }
    case 'received': {
      // return currency.toUpperCase() === 'BTC' || (currency.toUpperCase() === 'USDT' && to_address) ? to_address : email;
      return isCryptoCurrency(currency) && to_address ? addressCrypto(to_address) : sender_email;
    }
    case 'deposit': {
      return DIRECTLY_SOURCE.includes(source) ? source : 'Transf. bancaria';
    }
    case 'withdrawal': {
      if (account_bank && account_bank.account_bank && account_bank.account_bank.bank) {
        return account_bank.account_bank.bank;
      }
      return recipient_email;
    }
    case 'transfer': {
      if (account_bank && account_bank.first_name && account_bank.last_name) {
        return account_bank.first_name + ' ' + account_bank.last_name
      } else if (account_bank && account_bank.company_name) {
        return account_bank.company_name
      }
      return recipient_email;
    }
    case 'disbursement':
    case 'credit': {
      return recipient_email;
    }
    case 'vita_card': {
      return recipient_email;
    }
    case 'tax':
    case 'adjustment':
    case 'fee':
    case 'request_charge':
    case 'card_maintenance':
    case 'service_payment': {
      return description;
    }
    case 'debit': {
      return transfer_type === 'debit' ? name : '';
    }
    case 'exchange': {
      return isCryptoCurrency(currency)
        ? `Precio ${currency_to_default_currency_price
          ? `${formatMoney(currency_to_default_currency_price < 1 ? 1 / currency_to_default_currency_price : currency_to_default_currency_price, default_currency, 3, true)} ${default_currency.toUpperCase()}`
          : '1,00'
        }` : 'Monto recibido';
    }
    case "cash_back_coupon": {
      return identifierCoupon;
    }
    case 'payment': {
      return purpose_comentary;
    }
    case 'card_debit_adjustment':
    case 'card_credit_adjustment': {
      return `Ajuste ${account_bank?.merchant_name || ''}`;
    }
    case 'card_transaction': {
      return account_bank?.merchant_name || 'Compra';
    }
    default: {
      return '';
    }
  }
};

export const share = async (
  value,
  title = 'Comparte desde tus redes sociales',
) => {
  try {
    await Share.open({
      message: value,
      title,
    });
  } catch (error) { }
};

export const addNumber = (
  digit,
  currency,
  max,
  value,
  setValue,
  isOnlyKeyboard,
  precision = null,
) => {
  if (!isOnlyKeyboard) {
    // const decimal =
    //   precision !== null
    //     ? precision
    //     : currencies[currency.toLowerCase()].precision;
    const decimal = precision !== null ? precision : 2;
    let n = parseFloat(value < 0 ? 0 : value).toFixed(10);
    n = `${n}`.match(new RegExp(`^-?\\d+(?:\\.\\d{0,${decimal}})?`))[0];
    if (n.toString().indexOf('.') !== -1 && decimal === 0) {
      n = n.replace('.', '');
    }

    let number = 0;
    if (n.toString().indexOf('.') === -1) {
      number = parseFloat(
        `${n}${digit}`.match(new RegExp(`^-?\\d+(?:\\.\\d{0,${decimal}})?`))[0],
      );
    } else {
      const tokens = n.split('.');
      const numToLeft = tokens[1][0];
      const t = tokens[1].toString();
      const resto = t.substring(1, t.length);
      number = parseFloat(`${tokens[0]}${numToLeft}.${resto}${digit}`);
    }

    setValue(number, number, digit);
  } else {
    if (`${value === 0 ? '0' : value}`.length < max) {
      const v = `${value}${digit}`;
      setValue(v, v, digit);
    }
  }
};

export const deleteNumber = (
  currency,
  max,
  value,
  setValue,
  isOnlyKeyboard,
  isConsole = false,
  precision = null,
) => {
  if (!isOnlyKeyboard) {
    if (value && value > 0) {
      // const decimal =
      //   precision !== null
      //     ? precision
      //     : currencies[currency.toLowerCase()].precision;
      const decimal = precision !== null ? precision : 2;
      let valueT = parseFloat(value).toFixed(10);
      valueT = `${valueT}`.match(
        new RegExp(`^-?\\d+(?:\\.\\d{0,${decimal}})?`),
      )[0];
      if (valueT.toString().indexOf('.') !== -1 && decimal === 0) {
        valueT = valueT.replace('.', '');
      }
      valueT = valueT.substring(0, valueT.length - 1);
      let number = 0;

      if (valueT.indexOf('.') !== -1) {
        const tokens = valueT.split('.');
        const resto = tokens[1];

        const t = tokens[0].toString();
        const first = t.substring(0, t.length - 1);
        const numToRight = t.substring(t.length - 1, t.length);
        number = parseFloat(
          `${first.length >= 1 ? first : 0}.${numToRight}${resto}`,
        ).toFixed(decimal);
      } else {
        number = parseFloat(valueT ? valueT : 0).toFixed(10);
      }

      number = parseFloat(
        `${number}`.match(new RegExp(`^-?\\d+(?:\\.\\d{0,${decimal}})?`))[0],
      );

      setValue(number, number, null);
    }
  } else {
    if (value) {
      let v = `${value}`;
      v = v.substring(0, v.length - 1);
      setValue(v, v, null);
    }
  }
};

export const capitalize = (word) => {
  return word[0].toUpperCase() + word.slice(1).toLowerCase();
}

// Time asincrono para pausas en proceso 
export const timeout = async (ms) => {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
}

export const prepareBoldText = (text) => {
  const result = [];
  const regex = /<b>([^<]+)<\/b>/g;
  let lastIndex = 0;

  text.replace(regex, (match, valueBold, index) => {
    const textBeforeBold = text.substring(lastIndex, index);
    if (textBeforeBold.length > 0) {
      result.push({
        type: 'text',
        value: textBeforeBold
      });
    }

    result.push({
      type: 'bold',
      value: valueBold
    });

    lastIndex = index + match.length;
  });

  if (lastIndex < text.length - 1) {
    const textAfterLastBold = text.substring(lastIndex);
    result.push({
      type: 'text',
      value: textAfterLastBold
    });
  }

  return result;
};

function Version(s) {
  this.arr = s.split('.').map(Number);
}

Version.prototype.compareTo = function (v) {
  for (var i = 0; ; i++) {
    if (i >= v.arr.length) {
      return i >= this.arr.length ? 0 : 1;
    }
    if (i >= this.arr.length) {
      return -1;
    }
    var diff = this.arr[i] - v.arr[i];
    if (diff) {
      return diff > 0 ? 1 : -1;
    }
  }
};

export const isLowerVersion = (currentVersion, newVersion) => {
  const isV = new Version(currentVersion).compareTo(new Version(newVersion));
  return isV === -1;
};

export const validBoolean = (bool) => {
  let value = bool;

  if (value === 'true') {
    value = true;
  } else if (value === 'false') {
    value = false;
  }

  return value;
};

export const removeAccent = (text = '') => {
  return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

export const renderTransactionStatus = {
  started: 'Iniciada',
  completed: 'Completada',
  pending: 'Pendiente',
  denied: 'Rechazada',
  processed: 'Procesada',
  failed: 'Fallida',
  time_out: 'Caducado',
  checking: 'Pendiente',
};

export const VITA_CARD_ACCOUNT_STATUS = {
  PENDING: 'pending',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  DRAFT: 'draft',
  BLOCKED: 'blocked',
}

export const VITA_CARD_STATUS = {
  CREATED: 'created',
  PENDING: 'pending',
  ERROR: 'error',
  BLOCKED: 'blocked', // Congelada
  CANCELLED: 'cancelled',  // Eliminada
  ACTIVE: 'active', // Activada
}

export const options = [
  {
    label: 'Femenino',
    value: 'female',
  },
  {
    label: 'Masculino',
    value: 'male',
  },
  {
    label: 'Otro',
    value: 'other',
  },
];

export const civilStatusOptions = [
  {
    label: 'Soltero',
    value: 'single',
  },
  {
    label: 'Casado',
    value: 'married',
  },
  {
    label: 'Divorciado',
    value: 'divorced',
  },
  {
    label: 'Separado',
    value: 'separated',
  },
  {
    label: 'Viudo',
    value: 'widowed',
  },
];

export const formatTime = (time, format = "DD/MM/YYYY - HH:mm") => {
  return moment(time).format(format);
};

export const renderAccountTypes = {
  C: 'Cuenta corriente',
  S: 'Cuenta de ahorros',
  M: 'Cuenta maestra',
  V: 'Cuenta Vista',
  Checking: 'Cuenta corriente',
  Savings: 'Cuenta de ahorros',
  'Cuenta corriente': 'Cuenta corriente',
  'Cuenta de ahorros': 'Cuenta de ahorros',
  email: 'Correo electrónico',
  rambom_key: 'Llave aleatoria',
  code_cpf: 'CPF',
  code_cnpj: 'CNPJ',
  phone_number: 'Numero telefónico',

};